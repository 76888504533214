import React from "react";
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";

const Spinner = ({ size }) => {
  const localSize = size ? size : 50;
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    width: ${localSize}px;
    height: ${localSize}px;
  `;

  return (
    <div className="sweet-loading">
      <MoonLoader
        css={override}
        size={localSize}
        color="#9e3223"
        loading={true}
      />
    </div>
  );
}

export default Spinner;