import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Helmet from 'react-helmet';

import Footer from '../components/footer';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import NavSection from '../components/nav-section';
import { filter, isEmpty } from 'lodash';
import { isAFutureDay, isSameDay } from '../config/dateUtils';

class MainStoriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',
      eventFilter: '',
    };
  }

  handleCalendar = e => {
    this.setState({ selectedDate: e });
  };

  renderTodaysEvents = (eventVar, today, featuredImage, eventSpecificQuote, slug) => {
    return (
      eventVar.map((event, key) => {
        const eventDate = moment(moment(event.node.eventDate).format(
          'YYYY-MM-DD HH:mm'
        ));
        const todayDate = moment(moment(today).startOf('day').format('YYYY-MM-DD HH:mm'));
        const formattedEventDate = eventDate.format('MMMM DD, YYYY / HH:mm A')
        if (isSameDay(eventDate, todayDate)) {
          return (
            <div className="col-md-12 mt40 mb40 text-center event-card" key={key}>
              <img src={featuredImage.file.url} alt="" className="event-img" />
              <p className="mt30 font-gtaM black text-uppercase event-date">{formattedEventDate}</p>
              <h3 className="red mt20 font-tri event-title">{event.node.infoSectionTitle}</h3>
              <p className="mb30 black event-excerpt">{eventSpecificQuote.eventSpecificQuote}</p>
              <div className="row action-btns mb-5 pb-3 justify-content-center">
                <Link to={slug}>
                  <button className="btn">Learn More</button>
                </Link>
                <Link to={`${slug}#eventsCTA`}>
                  <button className="btn">RSVP</button>
                </Link>
              </div>
            </div>
          );
        }
      })
    )
  }

  // Stories - Mixed Layout for Loop
  getEvents = (mixedLayout) => {
    const eventPosts = [];
    for (let i = 0; i < mixedLayout.length; i++) {
      eventPosts.push(
        <div key={i} className="col-md-6 mt40 mb40 text-center event-card">
          <img
            className="event-img"
            src={mixedLayout[i].node.featuredImage.file.url}
            alt=""
          />
          <p className="mt30 font-gtaM black text-uppercase event-date">
            {mixedLayout[i].node.eventDateTime}
          </p>
          <h3 className="red mt20 font-tri event-title">
            {mixedLayout[i].node.articleTitle}
          </h3>
          <p className="mb30 black event-excerpt">
            {mixedLayout[i].node.blurb}
          </p>
          <div className="row action-btns mb-5 pb-3 justify-content-center">
            <Link to={`/stories/${mixedLayout[i].node.slug}`}>
              <button className="btn">Learn More</button>
            </Link>
            <a href="mailto:hello@palaciodememoria.com">
              <button className="btn">RSVP</button>
            </a>
          </div>
        </div>
      )
    }

    if(!isEmpty(eventPosts)) {
      return eventPosts;
    }

    return (
      <div className="row align-items-center justify-content-center w-100 mt-5 pt-4 mb-5">
          <Spinner />
      </div>
    )
  }

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const dateFormat = 'MMMM DD, Y / hh:mm A';

    const eventVar = this.props.data.allContentfulEventsSpecificDetails.edges;
    const { eventFilter, selectedDate } = this.state;
    const today = selectedDate ? selectedDate : new Date();
    const {
      eventDate: featuredEventDate,
      slug,
      featuredImage,
      eventSpecificQuote,
    } = this.props.data.allContentfulSectionHighlightEvent.edges[0].node.featuredEvent;


    const eventsArray = this.props.data.allContentfulEventsGeneralArticleMixedLayout.edges;
    const mixedLayout = eventFilter ?
    filter(eventsArray, {
      node: {
        category: eventFilter
      }
    }) : eventsArray;
    const set = new Set();
    eventsArray.map((data) => set.add(data.node.category));
    const categories = Array.from(set);

    const filterEvents = (e) => {
      this.setState({ eventFilter: e.target.value });
    }
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title="Stories" />

        <section id="upcomingEvents" className="pb-4">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="red font-tri">Stories at The Palacio</h1>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="events-happening">
              <div className="left-side mb-5">
                <DatePicker
                  selected={today}
                  onChange={(e) => this.handleCalendar(e)}
                  inline
                />
                <div className="upcoming-stories">
                  <p className="font-gtaM red text-uppercase upcoming-text">
                    Upcoming Events
                  </p>
                  {eventVar.map(event => {
                    const eventDate = moment(event.node.eventDate).format(
                      'YYYY-MM-DD hh:mm'
                    );
                    const todayDate = moment(today).startOf('day');
                    const formattedEventDate = moment(eventDate).format(dateFormat)
                    if (isAFutureDay(todayDate, moment(eventDate))) {
                      return (
                        <div className="upcoming-individual" key={event.node.slug}>
                          <p className="upcoming-title">
                            {event.node.infoSectionTitle}
                          </p>
                          <p className="upcoming-schedule">
                            {`${formattedEventDate} / ${event.node.infoSectionPlace.infoSectionPlace}`}
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="right-side mb-4">
                <div className="highlight-events mt-4">
                  <p className="font-gtaM red text-uppercase upcoming-text">
                    Highlight Event
                  </p>

                  <img src={featuredImage.file.url} alt="" />
                  <div className="more-details">
                    <div className="left-details">
                      <p className="font-gtaM red text-uppercase upcoming-text">
                        {featuredEventDate}
                      </p>
                      <p>
                        {eventSpecificQuote.eventSpecificQuote}
                      </p>
                    </div>
                    <div className="right-details">
                      <Link to={`/event-details/${slug}`}>
                        <button className="btn">Learn More</button>
                      </Link>
                      {/* <Link to={`${slug}#eventsCTA`}> */}
                      <a href="mailto:hello@palaciodememoria.com">
                        <button className="btn">Book Now</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="allEvents" className="pt-5 pb-5">
          <div className="container">
            <div className="row inline-block">
              {this.renderTodaysEvents(eventVar, today, featuredImage, eventSpecificQuote, slug)}
            </div>
          </div>
        </section>

        <section id="allStories" className="pb-5">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <p className="font-gtaM red text-uppercase upcoming-text mb-0">
                  EVENT:
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <form className="default-form">
                  <div className="stories-dropdown">
                    <select defaultValue="" onChange={(e) => filterEvents(e)}>
                      <option value="">
                        All
                      </option>
                      { categories.map((category) => <option value={category} key={category}>{category}</option>) }
                    </select>
                    <img src="/images/arrow.png" className="select-arrow" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row inline-block">
              { this.getEvents(mixedLayout) }
            </div>
          </div>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default MainStoriesPage;
export const pageQuery = graphql`
  query MainStoriesPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEventsGeneralArticleMixedLayout(sort: { fields: eventDateTime, order: DESC })  {
      edges {
        node {
          featuredImage {
            file {
              url
            }
          }
          articleTitle
          createdAt(formatString: "MMMM DD, YYYY / HH:mm A")
          blurb
          eventDateTime(formatString: "MMMM DD, YYYY / h:mm A")
          articleQuote {
            childMarkdownRemark {
                excerpt(pruneLength: 100)
              }
          }
          slug
          category
        }
      }
    }
    allContentfulEventsSpecificDetails(sort: { fields: eventDate }) {
      edges {
        node {
          slug
          featuredImage {
            file {
              url
            }
          }
          eventDate(formatString: "YYYY-MM-DDTHH:mm:SS")
          infoSectionTitle
          eventSpecificQuote {
            childMarkdownRemark {
              excerpt(pruneLength: 100)
            }
          }
          infoSectionPlace {
            infoSectionPlace
          }
        }
      }
    }
    allContentfulSectionHighlightEvent {
      edges {
        node {
          featuredEvent {
            eventDate(formatString: "MMMM DD, YYYY")
            slug
            featuredImage {
              file {
                url
              }
            }
            eventSpecificQuote {
              eventSpecificQuote
            }
          }
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
     allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
