import moment from 'moment';

/**
 * Identifies if the startDate is in the same day as
 * the endDate, dates are formatted as 'YYYY-MM-DD HH:mm'
 * @param   {moment, moment} in momentJS date
 * @returns {boolean} true if the startDate and endDate are in the same day
 */

export const isSameDay = (startDate, endDate) => {
  const formatStartDate = startDate.format('YYYY-MM-DD');
  const formatEndDate = endDate.format('YYYY-MM-DD');
  return moment(formatStartDate).isSame(formatEndDate, 'day');
};

/**
 * Checks if date is tomorrow or in the future date. Default format within the app is `YYYY-MM-DD HH:mm`
 * @param   {moment}  date MomentJS date
 * @returns {boolean} true if tomorrrow or future date
 */
export const isAFutureDay = (today, date) => date.isAfter(today, 'day');